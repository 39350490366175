import React from "react";
import { MdOutlineQuestionMark } from "react-icons/md";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import "./SemiCircleProgressBar.css";

export default function SemiCircleProgressBar({
  percentage,
  increaseOrDecrease,
  isUpgrade,
}) {
  const scaledPercentage = (percentage / 100) * 50;

  return (
    <div className="semi-donut-wrapper">
      {isUpgrade === "true" ? (
        <div className="semi-donut">
          <CircularProgressbar
            value={scaledPercentage}
            maxValue={100}
            strokeWidth={8}
            styles={buildStyles({
              rotation: 0.75,
              strokeLinecap: "round",
              textSize: "10px",
              pathColor:
                increaseOrDecrease === "increase"
                  ? "#00B11B"
                  : increaseOrDecrease === "null"
                  ? "#000"
                  : "#B10000",
              textColor: "#000",
              trailColor: "#ddd",
            })}
          />
          <div
            className={`progress-percentage ${
              increaseOrDecrease === "increase"
                ? "green"
                : increaseOrDecrease === "null"
                ? "black"
                : "red"
            }`}
          >
            {increaseOrDecrease === "increase" ? (
              <FaArrowUp />
            ) :increaseOrDecrease==="null"? (""): (
              <FaArrowDown />
            )}
            {percentage}%
          </div>
        </div>
      ) : (
        <div className="semi-donut">
          <CircularProgressbar
            value={scaledPercentage}
            maxValue={100}
            strokeWidth={8}
            styles={buildStyles({
              rotation: 0.75,
              strokeLinecap: "round",
              textSize: "10px",
              pathColor: "#bfbfbf",
              textColor: "#bfbfbf",
              trailColor: "#ddd",
            })}
          />
          <div className="progress-percentage">
            <MdOutlineQuestionMark />
          </div>
        </div>
      )}
    </div>
  );
}
