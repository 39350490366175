import React, { useState, useEffect } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import { TiPlus } from "react-icons/ti";
import { FaCheck } from "react-icons/fa";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import API_DOMAIN from "../../ApiConfig";

export default function UserConnect({
  connect,
  guestUser,
  name,
  isUsernameExists,
  fetchUserExist,
  connectIcon,
  isGuestAndUserID,
  setIsViewAsGuest,
}) {
  const [circleChecked, setCircleChecked] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCirclesCreated, setIsCirclesCreated] = useState(false);
  const [availableCircles, setAvailableCircles] = useState([]);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consoleErrorMessage, setConsoleErrorMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const userId = Cookies.get("userId");

  const guestUserAdd = "redirect";

  useEffect(() => {
    fetchUserExist();
  }, [fetchUserExist]);

  useEffect(() => {
    const fetchUserId = async () => {
      if (userId === "undefined") {
        const userName = Cookies.get("username");
        const response = await axios.get(
          `${API_DOMAIN}/login/detail/${userName}`
        );
        const data = response.data;
        const userId = data.userId;
        Cookies.set("userId", userId);
      }
    };
    fetchUserId();
  }, [userId]);

  const handleCircleChange = (option) => {
    setCircleChecked(option);
  };

  const handleConnect = () => {
    if (isGuestAndUserID) {
      setIsViewAsGuest(true);
    } else if (userId) {
      connect(true);
      setIsPopupOpen(true);
      fetchCircleData();
    } else {
      connect(false);
      Cookies.set("guestUserAdd", guestUserAdd);
      Cookies.set("guestUsername", name);
      window.location.href = "/";
    }
  };

  const fetchCircleData = async () => {
    try {
      const response = await axios.get(`${API_DOMAIN}/circle/view`, {
        params: { userId },
      });
      const result = response.data;
      if (result.success) {
        const { circles } = jwtDecode(result.token);
        const fetchedCircles = circles
          .map((link) => {
            const color = link.color;
            if (color === "N/A") {
              return null;
            } else {
              setIsCirclesCreated(true);
              return {
                type: link.name,
              };
            }
          })
          .filter(Boolean);
        setAvailableCircles(fetchedCircles);
      }
    } catch (error) {
      setEndPoint("/circle/view");
      setConsoleErrorMessage(error.message);
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const insertFollower = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_DOMAIN}/user/circle/save/${userId}`,
        { userId, targetUserId: guestUser, circleName: circleChecked },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data;

      if (result.success) {
        fetchUserExist();
      } else {
        const isNotFound = result.code === 404;

        if (isNotFound) {
          setEndPoint("/user/circle/save");
          setConsoleErrorMessage(result.error);
          setIsConsoleError(true);
          setConsoleError(result.error);
          return { success: false };
        } else {
          return { success: false };
        }
      }
    } catch (error) {
      setEndPoint("/user/circle/save");
      setConsoleErrorMessage(error.message);
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInsertFollower = async (close) => {
    await insertFollower();
    close();
  };

  return (
    <>
      {isUsernameExists ? (
        connectIcon === "Connect" ? (
          <div
            className="cwa-connect-btn"
            style={{
              backgroundColor: "#0000",
              color: "#383838",
              fontWeight: "bold",
              cursor: "none",
            }}
          >
            Connected
          </div>
        ) : (
          <FaCheck />
        )
      ) : connectIcon === "Connect" ? (
        <div className="cwa-connect-btn" onClick={handleConnect}>
          Connect
        </div>
      ) : (
        <TiPlus onClick={handleConnect} />
      )}

      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="add-circle-popup">
            <div className="acp-header">
              <div className="acp-title">Select a circle</div>
              <button className="acp-close" onClick={() => close()}>
                <RxCross2 />
              </button>
            </div>
            <div className="acp-body">
              {isCirclesCreated ? (
                availableCircles.map((link, index) => (
                  <div className="created-circle-name" key={index}>
                    <input
                      type="radio"
                      checked={circleChecked === link.type}
                      onChange={() => handleCircleChange(link.type)}
                      className="acp-radio-btn"
                    />
                    <div className="acp-radio-label">{link.type}</div>
                  </div>
                ))
              ) : (
                <div className="loader-class">
                  <div className="loader"></div>
                </div>
              )}
            </div>
            <div className="acp-footer">
              {circleChecked !== "customize" && (
                <div
                  className="acp-select-color-btn"
                  onClick={() => handleInsertFollower(close)}
                >
                  {isLoading ? <div className="spinner"></div> : "Add"}
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        endPoint={endPoint}
        idOfUser={userId}
        errorMessage={consoleErrorMessage}
      />
    </>
  );
}
