import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { AiFillHeart } from "react-icons/ai";
import { jwtDecode } from "jwt-decode";
import TinlyLogo from "../../Assets/TinlyLogo.png";
import Logo from "../../Assets/OneVaultLogo.svg";
import API_DOMAIN from "../../ApiConfig";
import "./MainLogin.css";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";

function MainLogin({
  guestUserAdd,
  guestReport,
  guestUsername,
  loginToView,
  site,
  iv,
}) {
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [consoleErrorMessage, setConsoleErrorMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const handleSignUpWithMavicsoft = () => {
    // Redirect to Mavicsoft's sign-up page
    window.location.href =
      "https://onevault.org/login.php?identity=d4828aeaeec4bba86f327245b94c381f5e2b73e68b89ef3f6fba147c2c144e02";
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userName = queryParams.get("userName");

  useEffect(() => {
    if (token && userName) {
      axios
        .post(
          `${API_DOMAIN}/login/fetch/profile`,
          { token, userName },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(async (response) => {
          const data = response.data;

          if (guestUserAdd) {
            window.location.href = `/guest/${guestUsername}`;
          } else if (guestReport) {
            window.location.href = `/guest/${guestUsername}`;
          } else if (loginToView) {
            window.location.href = `/direct/?site=${site}&iv=${iv}`;
          } else if (data.error === "Username already exists") {
            const detailResponse = await axios.get(
              `${API_DOMAIN}/login/detail/${userName}`
            );
            const detailResult = detailResponse.data;

            const userId = detailResult.userId;

            const userResponse = await axios.get(`${API_DOMAIN}/user/view`, {
              params: {
                userId: userId,
              },
            });
            const userResults = userResponse.data;

            const decodedToken = jwtDecode(userResults.token);
            const username = decodedToken.userName;

            window.location.href = `/@${username}`;
          } else {
            window.location.href = "/getstart";
          }
          await axios.post(
            `${API_DOMAIN}/existing/user`,
            { userName },
            { headers: { "Content-Type": "application/json" } }
          );
        })
        .catch((error) => {
          setEndPoint("/login/fetch/profile");
          setConsoleErrorMessage(error.message);
          setIsConsoleError(true);
          setConsoleError(error.message);
        });
    }

    const fetchToken = async () => {
      try {
        const response = await axios.get(
          `${API_DOMAIN}/login/detail/${userName}`
        );
        const result = response.data;
        const userId = result.userId;

        Cookies.set("userId", userId);

        const res = await axios.get(`${API_DOMAIN}/theme/view`, {
          params: {
            userId: userId,
          },
        });
        const resultTheme = res.data;
        if (resultTheme.success) {
          const { light, mid, dark } = jwtDecode(resultTheme.token).theme[0]
            .color;
          const themeArray = [light, mid, dark];
          Cookies.set("theme", JSON.stringify(themeArray), { expires: 1 / 12 });
        } else {
          const themeArray = ["#0ddfff", "#1fd093", "#30c027"];
          Cookies.set("theme", JSON.stringify(themeArray), { expires: 1 / 12 });
        }
      } catch (error) {
        setEndPoint("/theme/view");
        setConsoleErrorMessage(error.message);
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };

    if (userName && token) {
      Cookies.set("username", userName);
      Cookies.set("token", token);
      fetchToken();
    }
  }, [
    token,
    userName,
    guestUserAdd,
    guestUsername,
    iv,
    site,
    loginToView,
    guestReport,
  ]);

  const userId = Cookies.get("userId");

  return (
    <>
      <div className="login-wrapper">
        <div className="container d-flex justify-content-center align-items-center ">
          <div className="top-section">
            <div className="logo">
              <img src={TinlyLogo} alt="logo" />
            </div>
            <div className="mid-section">
              <p className="p1">Beyond Bio </p>
              <p className="p2">Empower Your Digital Persona! </p>
              <p className="p3"> Tinly</p>
            </div>
            <div className="ml-btn">
              <button className="btn" onClick={handleSignUpWithMavicsoft}>
                <img src={Logo} className="btn-img" alt="OneVaultLogo" />
                <span>Sign in with OneVault</span>
              </button>
            </div>
          </div>
          <div className="bottom-section">
            <p>
              Made &nbsp;
              <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
            </p>
          </div>
        </div>
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        endPoint={endPoint}
        idOfUser={userId}
        errorMessage={consoleErrorMessage}
      />
    </>
  );
}
export default MainLogin;
