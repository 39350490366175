import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import API_DOMAIN from "../../ApiConfig";
import axios from "axios";
import StepBar from "../../Components/StepBar/StepBar";
import "./LoginUser.css";
import ConfirmationMessage from "../../Components/ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../../Components/ConfirmationMessage/ConsoleError";

export default function LoginUser({ userId }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [allUsernames, setAllUsernames] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmAlert, setIsConfirmAlert] = useState(false);
  const [isValidInput, setIsValidInput] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [consoleErrorMessage, setConsoleErrorMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const savedFirstName = sessionStorage.getItem("firstName");
    const savedLastName = sessionStorage.getItem("lastName");
    const savedUserName = sessionStorage.getItem("userName");
    const savedEmail = sessionStorage.getItem("email");

    if (savedFirstName) setFirstName(savedFirstName);
    if (savedLastName) setLastName(savedLastName);
    if (savedUserName) setUsername(savedUserName);
    if (savedEmail) setEmail(savedEmail);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  useEffect(() => {
    const checkUsername = async () => {
      try {
        const response = await axios.get(
          `${API_DOMAIN}/user/get/usernames/all`
        );
        const result = response.data;
        if (result.success) {
          const availableUsernames = result.usernames;
          setAllUsernames(availableUsernames);
        }
      } catch (error) {
        setEndPoint("/user/get/usernames/all");
        setConsoleErrorMessage(error.message);
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    };
    checkUsername();
  }, []);

  const handleClick = () => {
    // Check if any field is empty
    if (!firstName || !lastName || !username) {
      setIsConfirmAlert(true);
      setIsVisible(true);
      return;
    }

    // Check if any validation fails
    if (errors.firstName || errors.lastName || errors.userName) {
      setIsValidInput(true);
      setIsConfirmAlert(false);
      setIsVisible(true);
      return;
    }

    sessionStorage.setItem("firstName", firstName);
    sessionStorage.setItem("lastName", lastName);
    sessionStorage.setItem("userName", username);
    navigate("/bio");
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("lastName");
    sessionStorage.removeItem("userName");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    if (name === "firstName") {
      setFirstName(trimmedValue);
    } else if (name === "lastName") {
      setLastName(trimmedValue);
    } else if (name === "userName") {
      setUsername(trimmedValue);
    }
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "firstName" || name === "lastName") {
      if (trimmedValue.length < 3) {
        setErrors({
          ...errors,
          [name]: "Name should include at least 3 characters.",
        });
      }
    }
    if (name === "userName") {
      const userNamePattern = /^[a-z0-9]{4,16}$/;
      if (!userNamePattern.test(trimmedValue)) {
        setErrors({
          ...errors,
          [name]:
            "Username should be 4-16 characters long and contain only lowercase alphanumerical characters.",
        });
      } else {
        const isAvailable = !allUsernames.includes(trimmedValue);
        if (!isAvailable) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Username already exists",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
        }
      }
    }
  };

  return (
    <>
      <div className="userLogin-container">
        <div className="ul-wrapper">
          <div className="ul-header">
            <StepBar num={1} />
          </div>
          <div className="ul-body">
            <div className="ulb-topic">
              Let's craft your Tinly experience together.
            </div>
            <div className="ulb-top">
              <div className="ulb-sub-topic">
                <h>Set your Name and Username</h>
              </div>
              <div className="ul-text">
                <div className="fname">
                  <div className="name-label">First Name</div>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="name-text"
                    maxLength={25}
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      handleChange(e);
                    }}
                  />
                  <div className="error-message">
                    {errors.firstName && <p>{errors.firstName}</p>}
                  </div>
                </div>
                <div className="lname">
                  <div className="name-label">Last Name</div>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="name-text"
                    maxLength={25}
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      handleChange(e);
                    }}
                  />
                  <div className="error-message">
                    {errors.lastName && <p>{errors.lastName}</p>}
                  </div>
                </div>
              </div>
              <div className="uname">
                <div className="name-label">User Name</div>
                <div className="input-user-name">
                  <span>@</span>
                  <input
                    type="email"
                    placeholder="Username"
                    className="name-text"
                    maxLength={16}
                    name="userName"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      handleChange(e);
                    }}
                  />
                </div>
                <div className="error-message">
                  {errors.userName && <p>{errors.userName}</p>}
                </div>
              </div>
              <p className="email">Your Email : {email}</p>
              <div className="ul-body-note">
                <p>
                  Please note: Name and Username can be changed only 3 times.
                  Choose wisely!
                </p>
              </div>
            </div>
            <div className="ul-bottom">
              <Link to="/getStart">
                <button onClick={handleClick}> &lt; Previous </button>
              </Link>
              <button onClick={handleClick}> Next &gt; </button>
            </div>
          </div>
          <div className="ul-footer">
            <p>
              Made &nbsp;
              <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
            </p>
          </div>
        </div>
      </div>
      <ConfirmationMessage
        isConfirmAlert={isConfirmAlert}
        isValidInput={isValidInput}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        endPoint={endPoint}
        idOfUser={userId}
        errorMessage={consoleErrorMessage}
      />
    </>
  );
}
