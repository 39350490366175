import React, { useRef, useState } from "react";
import Popup from "reactjs-popup";
import axios from "axios";
import Cookies from "js-cookie";
import { FaCamera } from "react-icons/fa";
import API_DOMAIN from "../../ApiConfig";
import CloseButton from "react-bootstrap/esm/CloseButton";
import "./ImageCrop.css";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import ConsoleError from "../ConfirmationMessage/ConsoleError";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 112;

function ImageCrop({
  previewImage,
  userPhoto,
  imageAdded,
  stateUserPhoto,
  imageOfUser,
  isBio,
}) {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState("");
  const [imgError, setImgError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [consoleErrorMessage, setConsoleErrorMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const userId = Cookies.get("userId");

  //button click loading animation
  const handleClickLoading = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (imgError) setImgError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setImgError("Image must be at least 120 x 120 pixels.");
          return setImgSrc("");
        }
        if (file.size > 1024 * 1024) {
          setImgError("File size exceeds 1MB. Please select a smaller file.");
          setImgSrc("");
        } else {
          setImgError("");
          setImgSrc(imageUrl);
        }
      });
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  //post profile photo
  const postProfileImage = async () => {
    try {
      const canvas = previewCanvasRef.current;
      const croppedImageDataURL = canvas.toDataURL(); // Get cropped image as data URL
      previewImage(croppedImageDataURL);
      userPhoto(croppedImageDataURL);
      imageOfUser(croppedImageDataURL);

      // Convert data URL to Blob
      const croppedImageBlob = await fetch(croppedImageDataURL).then((res) =>
        res.blob()
      );

      // Create a new File object from the Blob
      const image = new File([croppedImageBlob], "croppedImage.png", {
        type: "image/png",
      });

      const formData = new FormData();
      formData.append("image", image);
      formData.append("fk_user_id", userId);

      const response = await axios.post(
        `${API_DOMAIN}/profile/image/upload`,
        formData
      );

      const result = response.data;
      if (result.success) {
        imageAdded(true);
        stateUserPhoto(true);
      } else if (result.error) {
        setImgError(result.error);
      }
    } catch (error) {
      setEndPoint("/profile/image/upload");
      setConsoleErrorMessage(error.message);
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleClick = () => {
    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );
    postProfileImage();
  };

  const handleClickBio = async () => {
    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );

    const canvas = previewCanvasRef.current;
    const croppedImageDataURL = canvas.toDataURL(); // Get cropped image as data URL

    previewImage(croppedImageDataURL);
    userPhoto(croppedImageDataURL);
    imageOfUser(croppedImageDataURL);

    // Store the data URL directly in sessionStorage
    sessionStorage.setItem("image", croppedImageDataURL);
  };

  return (
    <>
      <Popup
        trigger={<FaCamera className="add-image-icon" />}
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="ic-popup">
            <div className="ic-header">
              <div className="ic-close">
                <button className="ic-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="ic-title">
                <h> Profile Image </h>
              </div>
            </div>
            <div className="ic-body">
              <div className="ic-button-choose-image">
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  className="add-file"
                  onChange={onSelectFile}
                  hidden
                />
                <button onClick={triggerFileInput} className="custom-button">
                  Choose file
                </button>
              </div>
              {imgError && <p className="img-error"> {imgError}</p>}
              {imgSrc && (
                <>
                  <div className="ic-img-display">
                    <ReactCrop
                      crop={crop}
                      onChange={(percentCrop) => setCrop(percentCrop)}
                      circularCrop
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    >
                      <img
                        ref={imgRef}
                        src={imgSrc}
                        alt="Upload"
                        style={{ maxHeight: "70vh" }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  </div>

                  <div className="ic-img-footer">
                    {!isBio ? (
                      <button
                        className="ic-crop-image"
                        onClick={() => {
                          handleClick();
                          handleClickLoading();
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="spinner"></div>
                        ) : (
                          "Crop & Save"
                        )}
                      </button>
                    ) : (
                      <button
                        className="ic-crop-image"
                        onClick={() => {
                          handleClickBio();
                          handleClickLoading();
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="spinner"></div>
                        ) : (
                          "Crop & Save"
                        )}
                      </button>
                    )}
                  </div>
                </>
              )}
              {crop && (
                <canvas
                  ref={previewCanvasRef}
                  className="ic-canvas"
                  style={{
                    display: "none",
                    border: "1px solid black",
                    objectFit: "contain",
                    width: 112,
                    height: 112,
                  }}
                />
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        endPoint={endPoint}
        idOfUser={userId}
        errorMessage={consoleErrorMessage}
      />
    </>
  );
}

export default ImageCrop;
