import React from "react";
import { MdOutlineQuestionMark } from "react-icons/md";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./CircleProgressBar.css";

export default function CircleProgressBar({ percentage, isUpgrade }) {
  const scaledPercentage = (percentage / 100) * 50;

  return (
    <div className="donut-wrapper">
      {isUpgrade === "true" ? (
        <div className="donut">
          <CircularProgressbar
            value={scaledPercentage}
            maxValue={50}
            strokeWidth={8}
            text={`${percentage}%`}
            styles={buildStyles({
              rotation: 1,
              strokeLinecap: "round",
              textSize: "12px",
              pathColor: "#7674B9",
              textColor: "#7674B9",
              trailColor: "#ddd",
            })}
          />
        </div>
      ) : (
        <div className="donut">
          <CircularProgressbar
            value={scaledPercentage}
            maxValue={50}
            strokeWidth={8}
            styles={buildStyles({
              rotation: 1,
              strokeLinecap: "round",
              textSize: "12px",
              pathColor: "#bfbfbf",
              textColor: "#bfbfbf",
              trailColor: "#ddd",
            })}
          />

          <div className="donut-progress-percentage">
            <MdOutlineQuestionMark />
          </div>
        </div>
      )}
    </div>
  );
}
