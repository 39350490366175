import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import User from "../../Assets/User.svg";
import CloseButton from "react-bootstrap/CloseButton";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import Popup from "reactjs-popup";
import ImageCrop from "../ImageCrop/ImageCrop";
import ImageAdd from "../ImageCrop/ImageAdd";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./ProfileImage.css";

export default function ProfileImage({
  userImage,
  userPhotoAdded,
  addedImage,
  fetchProfileImage,
  isBio,
  bioImage,
}) {
  const fileInputRef = useRef(null);
  const viewImageRef = useRef(null);
  const [previewImage, setPreviewImage] = useState("");
  const [isImageAdded, setIsImageAdded] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [userPhoto, setUserPhoto] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isViewImage, setIsViewImage] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [updateImage, setUpdateImage] = useState("");
  const [errorImage, setErrorImage] = useState("");
  const [consoleErrorMessage, setConsoleErrorMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const userId = Cookies.get("userId");

  const updateAvatar = (imgSrc) => {
    setNewImage(imgSrc);
  };

  //click outside to close the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewImageRef.current &&
        !viewImageRef.current.contains(event.target)
      ) {
        setIsViewImage(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [viewImageRef]);

  useEffect(() => {
    if (addedImage) {
      setPreviewImage(addedImage);
      setUserPhoto(addedImage);
      setIsImageAdded(true);
      userPhotoAdded(true);
      userImage(addedImage);
    } else if (bioImage) {
      setPreviewImage(bioImage);
      setUserPhoto(bioImage);
      setIsImageAdded(true);
      userPhotoAdded(true);
      userImage(bioImage);
    }
  }, [addedImage, userImage, userPhotoAdded, bioImage]);

  //delete profile photo
  const deleteProfileImage = async () => {
    try {
      await axios.delete(
        `${API_DOMAIN}/profile/image/delete?fk_user_id=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsImageAdded(false);
      setPreviewImage("");
      userPhotoAdded(false);
      userImage("");
    } catch (error) {
      setEndPoint("/profile/image/delete");
      setConsoleErrorMessage(error.message);
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    }
  };

  const handleRemoveImage = () => {
    setUserPhoto("");
    setNewImage("");
  };

  const handleSubmit = async (close) => {
    if (newImage) {
      await postProfileImage();
      close();
    } else if (userPhoto === "") {
      await deleteProfileImage();
      close();
    }
  };

  const handleSubmitBio = (close) => {
    if (newImage) {
      setPreviewImage(newImage);
      userPhotoAdded(true);
      userImage(newImage);
      sessionStorage.setItem("image", newImage);
      close();
    } else if (userPhoto === "") {
      sessionStorage.removeItem("image", bioImage);
      setIsImageAdded(false);
      setPreviewImage("");
      userPhotoAdded(false);
      userImage("");
      close();
    }
  };

  const postProfileImage = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", updateImage);
      formData.append("fk_user_id", userId);

      const response = await axios.post(
        `${API_DOMAIN}/profile/image/upload`,
        formData
      );

      setPreviewImage(newImage);
      userPhotoAdded(true);
      userImage(newImage);

      const result = response.data;
      if (!result.success) {
        if (result.error) {
          setErrorImage(result.error);
        }
      }
    } catch (error) {
      setEndPoint("/profile/image/upload");
      setConsoleErrorMessage(error.message);
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="profile-image">
        {isImageAdded ? (
          <Popup
            trigger={
              <img src={previewImage} alt="Profile" className="preview-image" />
            }
            modal
            nested
            closeOnDocumentClick={false}
          >
            {(close) => (
              <>
                <div className="photo-edit-popup">
                  <div className="pep-header">
                    <div className="pep-title">
                      <h>Profile Photo</h>
                    </div>
                    <button className="pep-close-btn" onClick={() => close()}>
                      <CloseButton style={{ height: "5px", width: "5px" }} />
                    </button>
                  </div>

                  <div className="pep-body">
                    <div
                      className="pep-body-left"
                      onClick={() => {
                        setIsViewImage(true);
                      }}
                    >
                      <img
                        src={newImage || userPhoto || User}
                        alt="Add New"
                        className={`user-photo ${
                          !newImage && !userPhoto ? "user-icon" : ""
                        }`}
                      />
                    </div>
                    <div className="pep-body-right">
                      <div
                        className="add-new"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <ImageAdd
                          updateAvatar={updateAvatar}
                          fetchProfilePic={fetchProfileImage}
                          stateUserPhoto={userPhotoAdded}
                          photoUser={userImage}
                          updateImage={setUpdateImage}
                          errorImage={errorImage}
                        />
                      </div>
                      <button
                        className="image-remove"
                        onClick={handleRemoveImage}
                      >
                        Remove
                      </button>
                    </div>
                  </div>

                  <div className="pep-footer">
                    {!isBio ? (
                      <button
                        className="save-btn"
                        onClick={() => {
                          handleSubmit(close);
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? <div className="spinner"></div> : "Save"}
                      </button>
                    ) : (
                      <button
                        className="save-btn"
                        onClick={() => {
                          handleSubmitBio(close);
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? <div className="spinner"></div> : "Save"}
                      </button>
                    )}
                  </div>
                </div>

                {isViewImage && (
                  <div className="view-image-modal" ref={viewImageRef}>
                    <img
                      src={newImage || userPhoto || User}
                      alt="Profile"
                      className={`view-image ${
                        !newImage && !userPhoto ? "user-icon" : ""
                      }`}
                    />
                  </div>
                )}
              </>
            )}
          </Popup>
        ) : (
          <div>
            <input className="add-image-file" />
            <ImageCrop
              previewImage={setPreviewImage}
              userPhoto={setUserPhoto}
              imageAdded={setIsImageAdded}
              stateUserPhoto={userPhotoAdded}
              imageOfUser={userImage}
              isBio={isBio}
            />
          </div>
        )}
      </div>
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        endPoint={endPoint}
        idOfUser={userId}
        errorMessage={consoleErrorMessage}
      />
    </>
  );
}
