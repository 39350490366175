import React from "react";
import { RxCross2 } from "react-icons/rx";
import { IoIosCloseCircle } from "react-icons/io";
import "./ConfirmationMessage.css";

export default function ConsoleError({
  consoleError,
  isConsoleError,
  closeConsoleError,
  closeNoSelectError,
  isUserSelected,
  action,
  endPoint,
  errorMessage,
  idOfUser,
  // errorType,
}) {
  if (endPoint && idOfUser && errorMessage) {
    sessionStorage.setItem("End Point", endPoint);
    sessionStorage.setItem("User Id", idOfUser);
    sessionStorage.setItem("Error Message", errorMessage);
  }

  return (
    <>
      {isConsoleError && (
        <div className="confirmation-msg">
          <div className="cm-container">
            <RxCross2
              className="cm-close"
              onClick={() => {
                closeConsoleError(false);
              }}
            />
            <div className="cm-warning-icon">
              <IoIosCloseCircle />
            </div>
            <div className="confirmation-msg-title">
              {/* {errorType === "Network" ? "Network Error!" : "Unexpected Error!"} */}
              Unexpected Error!
            </div>
            <div className="cm-body">
              <div className="cm-desc-error">{consoleError}</div>
            </div>
            <div className="cm-footer">
              <div
                className="cm-ok"
                onClick={() => {
                  closeConsoleError(false);
                }}
              >
                OK
              </div>
            </div>
          </div>
        </div>
      )}

      {isUserSelected && (
        <div className="confirmation-msg">
          <div className="cm-container">
            <RxCross2
              className="cm-close"
              onClick={() => {
                closeNoSelectError(false);
              }}
            />
            <div className="cm-warning-icon">
              <IoIosCloseCircle />
            </div>
            <div className="confirmation-msg-title">Unexpected Error!</div>
            <div className="cm-body">
              <div className="cm-desc-error">
                No users selected. Please select at least one user before
                clicking the "{action}" button.
              </div>
            </div>
            <div className="cm-footer">
              <div
                className="cm-ok"
                onClick={() => {
                  closeNoSelectError(false);
                }}
              >
                OK
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
