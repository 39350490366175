import React, { useState, useEffect } from "react";
import Select from "react-select";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import Axios from "axios";
import CloseButton from "react-bootstrap/CloseButton";
import Location from "../../Assets/Location.svg";
import API_DOMAIN from "../../ApiConfig";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import ConsoleError from "../ConfirmationMessage/ConsoleError";
import "./UserLocation.css";
import axios from "axios";

export default function UserLocation({
  countrySelected,
  citySelected,
  fetchLocation,
}) {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [inputCountry, setInputCountry] = useState(null);
  const [inputCity, setInputCity] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [locError, setLocError] = useState("");
  const [action, setAction] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [consoleError, setConsoleError] = useState("");
  const [isConsoleError, setIsConsoleError] = useState(false);
  const [isCountriesFetched, setIsCountriesFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consoleErrorMessage, setConsoleErrorMessage] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const userId = Cookies.get("userId");

  const fetchCountries = async () => {
    let country = await Axios.get(
      "https://countriesnow.space/api/v0.1/countries"
    );

    setCountries(country.data.data);
    setIsCountriesFetched(true);
  };

  const fetchCities = (country) => {
    const cities = countries.find((c) => c.country === country);
    setCities(cities.cities);
  };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  const handleCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedCity(null); // Clear selected city when country changes
    fetchCities(selectedOption.value);
  };

  const handleCity = (selectedOption) => {
    setSelectedCity(selectedOption);
    setLocError("");
  };

  //fetch location
  useEffect(() => {
    if (countrySelected && citySelected) {
      setSelectedCity(citySelected);
      setSelectedCountry(countrySelected);
      setInputCountry(countrySelected);
      setInputCity(citySelected);
      setIsAdded(true);
    }
  }, [countrySelected, citySelected]);

  //Post location to API
  const postLocation = async () => {
    try {
      const response = await axios.post(
        `${API_DOMAIN}/location`,
        { country: selectedCountry.value, city: selectedCity.value, userId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data;
      if (result.success) {
        setIsAdded(true);
        return { isSuccess: true };
      } else {
        const isNotFound = result.code === 404;
        if (isNotFound) {
          setEndPoint("/location");
          setConsoleErrorMessage(result.error);
          setIsConsoleError(true);
          setConsoleError(result.error);
        } else {
          setLocError(result.error);
        }
      }
    } catch (error) {
      setEndPoint("/location");
      setConsoleErrorMessage(error.message);
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  //Delete location
  const deleteLocation = async () => {
    try {
      await axios.delete(`${API_DOMAIN}/location/delete`, {
        data: {
          userId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAction("delete");
      setIsVisible(true);
      setIsAdded(false);
      setSelectedCity("");
      setSelectedCountry("");
      setInputCity("");
      setInputCountry("");
    } catch (error) {
      setEndPoint("/location/delete");
      setConsoleErrorMessage(error.message);
      if (error.message === "Failed to fetch") {
        setIsConsoleError(true);
        setConsoleError("Unable to connect. Please check back shortly!");
      } else {
        setIsConsoleError(true);
        setConsoleError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (close) => {
    if (!selectedCountry || !selectedCity) {
      setLocError("Both country and city is required.");
      return;
    }
    setIsLoading(true);
    const result = await postLocation();
    if (result && result.isSuccess) {
      close();
      setAction("add");
      setIsVisible(true);
      fetchLocation();
    }
  };

  const handleUpdate = async (close) => {
    if (!selectedCountry || !selectedCity) {
      setLocError("Both country and city is required.");
      return;
    }
    setIsLoading(true);
    const result = await postLocation();
    if (result && result.isSuccess) {
      close();
      setAction("update");
      setIsVisible(true);
      fetchLocation();
    }
  };

  const handleDelete = (close) => {
    deleteLocation();
    close();
  };

  const handleFetchCountries = () => {
    if (!isCountriesFetched) {
      fetchCountries();
    }
  };

  return (
    <>
      <Popup
        trigger={
          <button className="demographic">
            <img
              src={Location}
              alt=""
              className="demo-icon"
              onClick={() => {
                handleFetchCountries();
              }}
            />
            <span
              className="demo-title"
              onClick={() => {
                handleFetchCountries();
              }}
            >
              {inputCity && inputCountry ? (
                `${
                  inputCity.label?.slice(0, 20) || ""
                }, ${inputCountry.label?.slice(
                  0,
                  Math.max(0, 20 - (inputCity.label?.length || 0) - 2)
                )}${
                  (inputCity.label?.length || 0) +
                    (inputCountry.label?.length || 0) >
                  20
                    ? "..."
                    : ""
                }`
              ) : (
                <div>Location</div>
              )}
            </span>
          </button>
        }
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="location-popup">
            <div className="location-popup-header">
              <div className="location-box">
                <div className="location-close">
                  <button
                    className="location-close-btn"
                    onClick={() => close()}
                  >
                    <CloseButton style={{ height: "5px", width: "5px" }} />
                  </button>
                </div>
                <div className="location-title">
                  <h> Location </h>
                </div>
              </div>
            </div>
            <div className="location-body">
              <div className="location-country">
                {countries && (
                  <Select
                    className="select-country"
                    options={countries.map((country) => ({
                      value: country.country,
                      label: country.country,
                    }))}
                    onChange={handleCountry}
                    value={selectedCountry}
                    placeholder="Select your Country"
                  ></Select>
                )}
              </div>
              <div className="location-city">
                {cities && (
                  <Select
                    className="select-city"
                    options={cities.map((city) => ({
                      value: city,
                      label: city,
                    }))}
                    onChange={handleCity}
                    value={selectedCity}
                    placeholder="Select your City"
                  ></Select>
                )}
              </div>
              {locError && (
                <div className="loc-pb-error-message">{locError}</div>
              )}
            </div>
            <div className="location-footer">
              {!isAdded ? (
                <button
                  className="location-add"
                  onClick={() => handleSubmit(close)}
                >
                  {isLoading ? <div className="spinner"></div> : "Add"}
                </button>
              ) : (
                <div className="location-update-delete">
                  <button
                    className="location-delete"
                    onClick={() => handleDelete(close)}
                  >
                    Delete
                  </button>
                  <button
                    className="location-update"
                    onClick={() => handleUpdate(close)}
                  >
                    {isLoading ? <div className="spinner"></div> : "Update"}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      <ConfirmationMessage
        functionAdd={action === "add"}
        functionDelete={action === "delete"}
        functionUpdate={action === "update"}
        nameOfFunction="Location"
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <ConsoleError
        isConsoleError={isConsoleError}
        consoleError={consoleError}
        closeConsoleError={setIsConsoleError}
        endPoint={endPoint}
        idOfUser={userId}
        errorMessage={consoleErrorMessage}
      />
    </>
  );
}
