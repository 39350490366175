import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { MdOutlineQuestionMark } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import ClicksInsights from "../../Assets/ClicksInsights.svg";
import SemiCircleProgressBar from "../SemiCircleProgressBar/SemiCircleProgressBar";
import CircleProgressBar from "../CircleProgressBar/CircleProgressBar";
import "react-loading-skeleton/dist/skeleton.css";
import HomeLogo from "../HomeLogo/HomeLogo";
import API_DOMAIN from "../../ApiConfig";
import "./Insights.css";
import { jwtDecode } from "jwt-decode";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export default function Insights({ userPhoto, isPhoto, setIsInsightsActive }) {
  const [filterTab, setFilterTab] = useState("hours");
  const [totalViews, setTotalViews] = useState(0);
  const [hourlyViews, setHourlyViews] = useState([]);
  const [hourlyLabels, setHourlyLabels] = useState([]);
  const [monthlyComparison, setMonthlyComparison] = useState("");
  const [weeklyComparison, setWeeklyComparison] = useState("");
  const [weeklyViews, setWeeklyViews] = useState(0);
  const [monthlyViews, setMonthlyViews] = useState(0);
  const [monthlyLabels, setMonthlyLabels] = useState([]);
  const [monthlyChartData, setMonthlyChartData] = useState([]);
  const [hourlyCount, setHourlyCount] = useState(0);
  const [hourlyComparisonRate, setHourlyComparisonRate] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [todayComparisonRate, setTodayComparisonRate] = useState(0);
  const [weeklyChartData, setWeeklyChartData] = useState([]);
  const [weeklyLabels, setWeeklyLabels] = useState([]);

  const monthlyClicks = 308;
  const weeklyClicks = 108;
  const ctr = 20;
  const monthlyChange = 83;
  const weeklyChange = -5;
  const hourlyViewsData = hourlyViews;
  const weeklyViewsData = weeklyChartData;
  const monthlyViewsData = monthlyChartData;
  const userId = Cookies.get("userId");

  useEffect(() => {
    fetchTotalViews();
    fetchMonthlyViews();
    fetchHourlyComparison();
    fetchWeeklyViews();
  }, [userId]);

  const fetchTotalViews = async () => {
    try {
      const response = await axios.get(
        `${API_DOMAIN}/insight/view?userId=${userId}`
      );
      const result = response.data;
      if (result.success) {
        const {
          viewsCount,
          hourlyViews,
          viewTwoMonthDifference,
          viewTwoWeekDifference,
          viewLastMonthViewsCount,
          viewLastWeekViewsCount,
        } = jwtDecode(result.token);
        const labels = hourlyViews.map((view) => view.hourLabel);
        const data = hourlyViews.map((view) => view.viewsCount);

        setTotalViews(viewsCount);
        setMonthlyComparison(viewTwoMonthDifference);
        setWeeklyComparison(viewTwoWeekDifference);
        setMonthlyViews(viewLastMonthViewsCount);
        setWeeklyViews(viewLastWeekViewsCount);
        setHourlyViews(data);
        setHourlyLabels(labels);
      }
    } catch (error) {
      console.log("Error fetching total Views: ", error);
    }
  };

  const fetchMonthlyViews = async () => {
    try {
      const response = await axios.get(
        `${API_DOMAIN}/insight/view/month?userId=${userId}`
      );
      const result = response.data;
      if (result.success) {
        const { monthlyViews } = jwtDecode(result.token);
        const labels = monthlyViews.map((view) => view.day);
        const data = monthlyViews.map((view) => view.viewsCount);

        setMonthlyLabels(labels);
        setMonthlyChartData(data);
      }
    } catch (error) {
      console.log("Error in fetching monthly views: ", error);
    }
  };

  const fetchHourlyComparison = async () => {
    try {
      const response = await axios.get(
        `${API_DOMAIN}/insight/view/comparison?userId=${userId}`
      );
      const result = response.data;
      if (result.success) {
        const {
          view24HoursCount,
          view24HoursDifference,
          viewTodayCount,
          viewTodayDifference,
        } = jwtDecode(result.token);

        setHourlyCount(view24HoursCount);
        setHourlyComparisonRate(view24HoursDifference);
        setTodayCount(viewTodayCount);
        setTodayComparisonRate(viewTodayDifference);
      }
    } catch (error) {
      console.log("Error in fetching hourly comparison: ", error);
    }
  };

  const fetchWeeklyViews = async () => {
    try {
      const response = await axios.get(
        `${API_DOMAIN}/insight/view/week?userId=${userId}`
      );
      const result = response.data;
      if (result.success) {
        const { weeklyViews } = jwtDecode(result.token);
        const labels = weeklyViews.map((view) => view.day);
        const data = weeklyViews.map((view) => view.viewsCount);

        setWeeklyChartData(data);
        setWeeklyLabels(labels);
      }
    } catch (error) {
      console.log("Error in fetching weekly views: ", error);
    }
  };

  const data = {
    labels: ["Social Media Links", "Custom Links", "Private Links"],
    datasets: [
      {
        data: [61, 28, 11],
        backgroundColor: ["#0488B3", "#A34444", "#E4D100"],
        hoverBackgroundColor: ["#1565C0", "#C62828", "#F9A825"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const chartLabels = {
    hours: hourlyLabels,
    days: weeklyLabels,
    month: monthlyLabels,
  };

  const chartData = {
    labels: chartLabels[filterTab],
    datasets: [
      {
        label: "Views",
        data:
          filterTab === "hours"
            ? hourlyViewsData
            : filterTab === "days"
            ? weeklyViewsData
            : monthlyViewsData,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(
            0,
            0,
            0,
            context.chart.height
          );
          gradient.addColorStop(0, "rgba(255, 255, 255, 0.35)");
          gradient.addColorStop(1, "rgba(0,60,255,0.35)");
          return gradient;
        },

        borderColor: "rgba(0,60,255,0.30)",
        borderWidth: 1,
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomLeft: 0,
          bottomRight: 0,
        },
        borderSkipped: "bottom",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text:
            filterTab === "hours"
              ? "Last 24 Hours"
              : filterTab === "days"
              ? "Last 7 Days"
              : "Last 30 Days",
          font: {
            size: 10,
            weight: "normal",
          },
          color: "#000",
        },
        ticks: {
          color: "#000",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
        ticks: {
          stepSize: filterTab === "hours" ? 1 : filterTab === "days" ? 10 : 10,
          padding: 10,
          color: "#000",
          font: {
            size: 10,
          },
        },
        title: {
          display: true,
          text: "Number of Views",
          font: {
            size: 10,
            weight: "normal",
          },
          color: "#000",
        },
      },
    },
  };

  const maxYAxisValue = 100;
  const chartHeight = (maxYAxisValue / 10) * 30;

  return (
    <div className="insights-modal">
      <div className="insights-menu-bar">
        <div>
          <HomeLogo />
        </div>
        <div className="imb-title">Insights</div>
        <div className="insights-to-home">
          {isPhoto ? (
            <div
              onClick={() => {
                setIsInsightsActive(false);
              }}
            >
              <img src={userPhoto} alt="" className="home-photo" />
            </div>
          ) : (
            <FaUserCircle
              className="insights-to-home-icon"
              onClick={() => {
                setIsInsightsActive(false);
              }}
            />
          )}
        </div>
      </div>

      <div className="insights-header">
        <div className="insights-title">
          <h>Insights</h>
        </div>
        <div className="insights-close">
          <button
            className="insights-close-btn"
            onClick={() => {
              setIsInsightsActive(false);
            }}
          >
            <RxCross2 />
          </button>
        </div>
      </div>
      <div className="insights-container">
        <div className="total-views-body">
          <div className="tv-top">
            <div className="total-views-title">Total Views</div>
            <div className="total-views-desc">
              Number of times your profile has been viewed
            </div>
            <div className="total-views-count">{totalViews}</div>
          </div>

          <div className="tv-middle">
            <div className="tv-filter-sec">
              <div className="filter-by">Filter By: </div>
              <div
                className={`filter-by-tabs ${
                  filterTab === "hours" ? "active" : ""
                }`}
                onClick={() => {
                  setFilterTab("hours");
                }}
              >
                24 hours
              </div>
              <div
                className={`filter-by-tabs ${
                  filterTab === "days" ? "active" : ""
                }`}
                onClick={() => {
                  setFilterTab("days");
                }}
              >
                Last 7 days
              </div>
              <div
                className={`filter-by-tabs ${
                  filterTab === "month" ? "active" : ""
                }`}
                onClick={() => {
                  setFilterTab("month");
                }}
              >
                Last 30 days
              </div>
            </div>
            <div className="tv-chart">
              <div
                style={{
                  height: `${chartHeight}px`,
                  width: `${50 * chartData.labels.length}px`,
                }}
              >
                <Bar data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>

          <div className="tv-bottom">
            <div className="tv-progress-sec">
              <div className="tv-progress-text">
                <div className="tv-progress-count">{monthlyViews}</div>
                <div className="tv-progress-desc">Total Views Last Month</div>
              </div>
              <SemiCircleProgressBar
                percentage={monthlyComparison}
                increaseOrDecrease={
                  monthlyComparison > 0
                    ? "increase"
                    : monthlyComparison === 0
                    ? "null"
                    : "decrease"
                }
                isUpgrade={"true"}
              />
            </div>

            <div className="tv-progress-sec">
              <div className="tv-progress-text">
                <div className="tv-progress-count">{weeklyViews}</div>
                <div className="tv-progress-desc">Total Views Last Week</div>
              </div>
              <SemiCircleProgressBar
                percentage={weeklyComparison}
                increaseOrDecrease={
                  weeklyComparison > 0
                    ? "increase"
                    : weeklyComparison === 0
                    ? "null"
                    : "decrease"
                }
                isUpgrade={"true"}
              />
            </div>

            <div className="tv-progress-sec">
              <div className="tv-progress-text">
                <div className="tv-progress-count">{hourlyCount}</div>
                <div className="tv-progress-desc">
                  Total Views Last 24 Hours
                </div>
              </div>
              <SemiCircleProgressBar
                percentage={hourlyComparisonRate}
                increaseOrDecrease={
                  hourlyComparisonRate > 0
                    ? "increase"
                    : hourlyComparisonRate === 0
                    ? "null"
                    : "decrease"
                }
                isUpgrade={"true"}
              />
            </div>

            <div className="tv-progress-sec">
              <div className="tv-progress-text">
                <div className="tv-progress-count">{todayCount}</div>
                <div className="tv-progress-desc">
                  Total Views
                  <br /> Today
                </div>
              </div>
              <SemiCircleProgressBar
                percentage={todayComparisonRate}
                increaseOrDecrease={
                  todayComparisonRate > 0
                    ? "increase"
                    : todayComparisonRate === 0
                    ? "null"
                    : "decrease"
                }
                isUpgrade={"true"}
              />
            </div>
          </div>

          <div className="insights-upgrade-msg">
            <div className="new-insight-title">Peak Time</div>
            <div className="new-insight-details">
              <div className="sample-data-title">This is a sample data.</div>
              <div className="sample-data-desc">
                Your profile received the highest number of views @6.20 pm
              </div>
            </div>
            <div className="unlock-insights">Unlock More Insights Now!</div>

            <div className="unlock-insights-details">
              <div className="unlock-insights-desc">
                Get deeper insights with Tinly Pro! Start your 30-days free
                trial and explore detailed analytics. Cancel anytime.
              </div>
              <div className="unlock-insights-sections">
                <div className="unlock-insights-icon">
                  <FaCheck />
                </div>
                <div className="unlock-insights-sent">
                  Track unique views and clicks
                </div>
              </div>
              <div className="unlock-insights-sections">
                <div className="unlock-insights-icon">
                  <FaCheck />
                </div>
                <div className="unlock-insights-sent">
                  Boost engagement with Click-Through-Rate(CTR) insights.
                </div>
              </div>
              <div className="unlock-insights-sections">
                <div className="unlock-insights-icon">
                  <FaCheck />
                </div>
                <div className="unlock-insights-sent">
                  See where your audience is with country Metrics.
                </div>
              </div>
            </div>
            <div className="insights-upgrade-btn">Upgrade</div>
          </div>
        </div>

        <div className="unique-views-body">
          <div className="uv-top">
            <div className="unique-views-sec">
              <div className="uvs-title">Unique Views</div>
              <div className="uvs-desc">
                Number of times unique visitors have clicked your profile URL
              </div>
              <div className="uvs-count">
                <MdOutlineQuestionMark />
              </div>
            </div>
            <div className="repeat-views-sec">
              <div className="rvs-title">Repeat Views</div>
              <div className="rvs-count">
                <MdOutlineQuestionMark />
              </div>
            </div>
          </div>
          <div className="uv-middle">
            <div className="profile-visitors">
              <div className="pv-title">Profile Visitors</div>
              <div className="pv-desc">
                See who's been checking out your profile.
              </div>
              <div className="pv-details">
                <div className="pv-profile-image"></div>
                <div className="pv-details-sec">
                  <div className="pv-name">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={100} />
                    </SkeletonTheme>
                  </div>
                  <div className="pv-designation">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={60} />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="pv-timestamp">3 mins</div>
              </div>
              <div className="pv-details">
                <div className="pv-profile-image"></div>
                <div className="pv-details-sec">
                  <div className="pv-name">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={100} />
                    </SkeletonTheme>
                  </div>
                  <div className="pv-designation">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={60} />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="pv-timestamp">12h</div>
              </div>
              <div className="pv-details">
                <div className="pv-profile-image"></div>
                <div className="pv-details-sec">
                  <div className="pv-name">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={100} />
                    </SkeletonTheme>
                  </div>
                  <div className="pv-designation">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={60} />
                    </SkeletonTheme>
                  </div>
                </div>
                <div className="pv-timestamp">1d</div>
              </div>
            </div>

            <div className="profile-visitor-source">
              <div className="pv-title">Visitor Sources</div>
              <div className="pv-desc">
                The most important channels contributing to unique views.
              </div>
              <div className="visitor-sources-details">
                <div className="platform-details">
                  <div className="platform-name">Social media platforms</div>
                  <div className="platform-progress">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={100} />
                    </SkeletonTheme>
                  </div>
                </div>

                <div className="platform-details">
                  <div className="platform-name">Direct Visits</div>
                  <div className="platform-progress">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={100} />
                    </SkeletonTheme>
                  </div>
                </div>

                <div className="platform-details">
                  <div className="platform-name">Search Engines</div>
                  <div className="platform-progress">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={100} />
                    </SkeletonTheme>
                  </div>
                </div>

                <div className="platform-details">
                  <div className="platform-name">Referral Sites</div>
                  <div className="platform-progress">
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#9a9a9a">
                      <Skeleton height={5} width={100} />
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="insights-upgrade-msg">
            <div className="unlock-insights">Unlock More Insights Now!</div>

            <div className="unlock-insights-details">
              <div className="unlock-insights-desc">
                Get deeper insights with Tinly Pro! Start your 30-days free
                trial and explore detailed analytics. Cancel anytime.
              </div>
              <div className="unlock-insights-sections">
                <div className="unlock-insights-icon">
                  <FaCheck />
                </div>
                <div className="unlock-insights-sent">
                  Explore Unique Views to see who’s visiting your profile
                </div>
              </div>
              <div className="unlock-insights-sections">
                <div className="unlock-insights-icon">
                  <FaCheck />
                </div>
                <div className="unlock-insights-sent">
                  Discover Viewer Details with insights into who’s checking you
                  out
                </div>
              </div>
              <div className="unlock-insights-sections">
                <div className="unlock-insights-icon">
                  <FaCheck />
                </div>
                <div className="unlock-insights-sent">
                  Analyze Visitor Sources with comprehensive data on where your
                  traffic is coming from
                </div>
              </div>
            </div>
            <div className="insights-upgrade-btn">Upgrade</div>
          </div>
        </div>

        <div className="clicks-insights-body">
          <div className="ci-top-sec">
            <img src={ClicksInsights} alt="" className="clicks-image" />
            <div className="clicks-title">
              <div className="clicks">Clicks</div>
              <div className="clicks-count">
                <MdOutlineQuestionMark />
                Total Clicks
              </div>
            </div>
          </div>

          <div className="progress-bar-container">
            <div className="tv-progress-sec">
              <div className="tv-progress-text">
                <div className="tv-progress-count">
                  <MdOutlineQuestionMark />
                </div>
                <div className="tv-progress-desc">Total Clicks Last Month</div>
              </div>
              <SemiCircleProgressBar
                percentage={5}
                increaseOrDecrease={"increase"}
                isUpgrade={"false"}
              />
            </div>

            <div className="tv-progress-sec">
              <div className="tv-progress-text">
                <div className="tv-progress-count">
                  <MdOutlineQuestionMark />
                </div>
                <div className="tv-progress-desc">Total Clicks Last Week</div>
              </div>
              <SemiCircleProgressBar
                percentage={68}
                increaseOrDecrease={"decrease"}
                isUpgrade={"false"}
              />
            </div>

            <div className="tv-progress-sec">
              <div className="tv-progress-text">
                <div className="tv-progress-count">
                  <MdOutlineQuestionMark />
                </div>
                <div className="tv-progress-desc">Click Through Rate</div>
              </div>
              <CircleProgressBar percentage={90} isUpgrade={"false"} />
            </div>
          </div>

          <div className="insights-upgrade-msg">
            <div className="unlock-insights">Unlock More Insights Now!</div>

            <div className="unlock-insights-details">
              <div className="unlock-insights-desc">
                Get deeper insights with Tinly Pro! Start your 30-days free
                trial and explore detailed analytics. Cancel anytime.
              </div>
              <div className="unlock-insights-sections">
                <div className="unlock-insights-icon">
                  <FaCheck />
                </div>
                <div className="unlock-insights-sent">
                  Upgrade now to track clicks on your social media, custom, and
                  private links.
                </div>
              </div>
            </div>
            <div className="insights-upgrade-btn">Upgrade</div>
          </div>
        </div>
      </div>
    </div>
  );
}
